import React, { useState } from 'react'

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => {
    const [btnText, setBtnText] = useState("Submit");
    const [showSuccess, setShowSuccess] = useState(false);
    function formSubmit(e) {
        e.preventDefault();
        let timeleft = 1;
        let timer = setInterval(function () {
            if (timeleft > 3) {
                setBtnText("Submit");
                setShowSuccess(true);
                clearInterval(timer);
            }
            else {
                let dots = '';
                for (let i = 1; i <= timeleft; i++) {
                    dots = dots + '.';
                }
                setBtnText("Submit" + dots);
                timeleft += 1;
            }
        }, 1000);
    }
    return (
        <Layout>
            <div className="container py-4">
                <div className="text-center">
                    <h1>Contact us</h1>
                    <p>Do you have any questions? Please do not hesitate to contact us directly by filling below form. Our team will come back to you shortly to help you.</p>
                </div>
                <div className="row">
                    <div className="col-md-9">
                        <form onSubmit={(e) => formSubmit(e)}>
                            <div className="mb-3">
                                <label className="form-label">Name</label>
                                <input className="form-control" type="" placeholder="Please enter your name" required />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Email Address</label>
                                <input className="form-control" type="email" placeholder="Please enter your email address" required />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Message</label>
                                <textarea className="form-control" type="text" placeholder="Please type your message" style={{ height: "10rem" }} required></textarea>
                            </div>
                            <div className="d-grid">
                                <button className="btn btn-success btn-lg" type="submit">{btnText}</button>
                            </div>
                        </form>
                        {showSuccess && (
                            <div className="alert alert-success mt-3" role="alert">
                                Success! We will get back to you soon.
                            </div>
                        )}
                    </div>
                    <div className="col-md-3 text-center pt-md-5">
                        <ul className="list-unstyled mb-0 mt-5">
                            <li className="mb-4">
                                <h4>Address</h4>
                                <p>Lahore, Punjab 54000, Pakistan</p>
                            </li>
                            <li>
                                <h4>Our Email</h4>
                                <p>contact@taxcalculatorpk.com</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
export const Head = () => <Seo title="Income Tax Calculator Pakistan - 2024-2025" />

export default IndexPage
